(function($){
  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    var copy_with_success = false;
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      copy_with_success = true;
    } catch (err) {
    }

    document.body.removeChild(textArea);
    return copy_with_success;
  }
  function copyTextToClipboard(text) {
    var copy_with_success = false;
    if (!navigator.clipboard) {
      copy_with_success = fallbackCopyTextToClipboard(text);
      return copy_with_success;
    }
    navigator.clipboard.writeText(text).then(function() {
      copy_with_success = true;
    }, function(err) {
    });
    return copy_with_success;
  }

  function initCopyText(){
    $('.copy-link-js').on('click', function(){
      console.log('copy link');
      var copy_with_success = copyTextToClipboard($(this).data('url'));
      console.log('copy with success');
      console.log(copy_with_success);
      // if (copy_with_success){
        var msg = $(this).data('msg');
        console.log(msg);
        var $msg = $('<div class="sharebox-message">'+msg+'</div>').appendTo('.sharebox-message-container');
        setTimeout(function(){
          $msg.remove();
        }, 5000);
      // }
    });
  }

  // var copyBobBtn = document.querySelector('.js-copy-bob-btn'),
  //   copyJaneBtn = document.querySelector('.js-copy-jane-btn');

  // copyBobBtn.addEventListener('click', function(event) {
  //   copyTextToClipboard('Bob');
  // });


  // copyJaneBtn.addEventListener('click', function(event) {
  //   copyTextToClipboard('Jane');
  // });
  $(initCopyText);

})(jQuery);
